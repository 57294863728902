export default {
  'Language': '中文',
  'checkText': "请选择",
  'inputText': "请输入",
  'quxiao': "取 消",
  'queding': "确 定",
  'jincishengxiao': "仅此生效",
  'baocunshezhe': "保存设置",
  'sl_time': {
    'text': '请选择时间段',
    'begin': "选择开始日期",
    'over': "选择结束日期"
  },
  'bt_clear': "清空",
  'bt_search': "查询",
  'bt_new': "新建工单",
  'bt_senior_check': "高级查询",
  'bt_retrac': "收起",
  'bt_check': "内容显示",
  'processes': "工作状态",
  'workOrderNo': "工单号",
  'createdName': "创建人",
  'customerName': "客户姓名",
  'personLiable': "研发跟进人",
  'faeOperatorName': "FAE处理人",
  'salesman': "销售",
  'platform': "平台",
  'stations': "当前站点",
  'type': "客诉类型",
  'urgentType': "紧急程度",
  'transformFlag': "是否转出",
  'description': "描述内容",
  'productModelType': "机型",
  'selfFlag': "诉源",
  'ownerTypeSelf': "当前员工",
  'ownerTypeOthers': "全部员工",

  'totalCount_text_TOP': "根据筛选条件共查询到",
  'totalCount_text_over': "条结果",

  'process': "问题状态",
  'completedByName': "完成人",
  'createdTime': "创建时间",
  'station': "当前工站",
  'area': "大区",
  'keyword': "关键词",
  'remark': "备注",
  'customerDescription': "客户原话/其他",
  'operate': "操作",

  "lookAndDo": "查看详情/工单处理",
  "chongqi": "重启",
  "wancheng": "完成", 
  "baingengfae": "变更FAE负责人",
  "huisuyanfa": "回溯研发工单",
  "xiugaijichu": "修改订单基础信息",
  "chakanshijian": "查看时间线",
  "shanchu": "删除",  

  "shifoushanchu": "是否删除此订单",
  "quedingshanchu": "确认删除",
  "zanbushanchu": "暂不删除",

  "shifouhuisu": "是否确认回溯此研发工单",
  "quedinghuisu": "确认回溯",
  "zanbuhuisu": "暂不删除",

  "khgn": "客户(国内)",
  "productType": "产品类型", 
  "networkType": "网络机制",

  "di": "低",
  "zhong": "中",
  "gao": "高",
  "jigao": "极高",

  "tupian": "图片",
  "tupiantixing": "选择图片（仅限jpg, .png）",
  "wenjian": "文件",
  "fujian": "附件",
  "dianjishangchuan": "点击上传",
  "shijianxianliebiao": "时间线列表",
  "gongdanliucheng": "工单流程",
  "tianjiagongdan": "添加工单",
  "tixing": "提醒",
  "tixingxijie":
    `来料问题：供应商提供的物料本身存在的问题。（如电源线质量问题、麦克风质量问题和PCB元器件质量问题等）\n\r
        组包问题：工厂组装问题，包括硬件组装、包装打包等。（如工厂焊接虚焊、定制出厂指令错漏、包装漏配件、包材打印错误等）\n\r
        硬件问题：因硬件设计引起的功能性问题。（如断油电功能异常、GPS不定位和射频问题等）\n\r
        软件问题：因软件逻辑引起的功能性问题。（如产品标准功能问题和定制功能问题等）\n\r
        平台问题：WEB端和APP侧的问题。（如平台功能性问题、API问题和IOTHUB问题等）\n\r
        其他问题：除以上客诉类型的其他问题。（如客户操作问题、安装接线问题和产品功能理解有偏差问题等）`,
  "yujiwanchengshijian": "预计完成时间",
  "quedingxiugai": "确定修改",
  //客诉类型
  "ptwt": "平台问题",
  "ruanjianwenti": "软件问题",
  "yingjianwenti": "硬件问题",
  "lailiaowenti": "来料问题",
  "zubaowenti": "组包问题",
  "qitawetni": "其他问题",
  //机型
  "qtlx": "其他机型",
  "zdy": "自定义",
  "shijueanfang": "视觉安防产品",
  "wuxianchanpin": "无线产品",
  "youxianchanpin": "有线产品",
  "xuehengka": "学生卡",
  "pingtai": "平台",
  //大区
  "dianshang": "电商",
  "dongnanya": "东南亚区",
  "guojika": "国际KA",
  "guopjiduoge": "国际多个区域",
  "guoneika": "国内KA",
  "guoneiwuxian": "国内无线",
  "guoneiyouxina": "国内有线",
  "beimei": "北美区",
  "lamei": "拉美区",
  "nanya": "南亚区",
  "ouzhou": "欧洲区",
  "zhongfei": "中非区",
  "dianmo": "电摩",
  "wuliuzichan": "物流资产",
  //平台选项
  "tqzx": "途强在线",
  "tqsx": "途强视讯",
  "fkpt": "风控平台",
  "simkpt": "SIM卡平台",
  "hdd": "和丁丁",
  "aczx": "爱车在线",
  "fgdzpt": "法国定制平台",
  "qita": "其他 ",
  //进度条
  "jindu1": "开始",
  "jindu2": "FAE处理中",
  "jindu3": "转发至研发",
  "jindu4": "研发处理中",
  "jindu5": "研发处理完成",
  "jindu6": "FAE确认完成中",
  "jindu7": "完成",


  "clxq": "处理/详情",
  "gxzt": "更新状态",
  "sjxx": "时间信息：",
  "zzsj": "转至时间：",
  "wcsj": "完成时间：",
  "stuckPoint": "当前卡点",
  "solution": "问题进度",
  "zygz": "转移工站",
  "zygd": "转移工单",
  "cxzy": "撤销转移",
  "xggdzt": "修改工单状态",
  "jibenxinxi": "基本信息",

  "dianshangqiangxing": "电商详情",
  "deviceStatus": "设备状态",
  "channel": "购买渠道",
  "country": "国家",
  "otherContactInfo": "其他联系方式",
  "ledStatus": "设备LED灯状态",
  "simCardOperator": "SIM卡运营商",
  "usageScenario": "使用场景",
  "installedVehicleModel": "安装的车型号",

  "kfxiangqing": "客服详情",
  "phoneNumber": "客户手机号",
  "title": "标题",
  "reason": "问题原因",
  "wentichulifangan": "问题处理方案",
  "yanfaxiangqing": "研发详情",
  "jieshishuoming": "解释说明",
  "fenpeifae": "分配FAE操作员",
  //progressStatus  tag

  "geren": "个人",
  "kefu": "客服",
  "yanfa": "研发",
  "xiaochengxu": "小程序",
  "dianshang": "电商",
  "quanbu": "全部",
  "zijian": "自建",
  "yewu": "业务",
  "buxian": "不限",
  "yihcuangjian": "已创建",
  "yiwancheng": "已完成",
  "weiwancheng": "未完成",
  "yizhuanchu": "已转出",
  "zhuanchuhou": "转出后，研发已完成",
  "shi": "是",
  "fou": "否",
  "disanfang": "第三方",
  "huizhouruanjian": "惠州软件研发",
  "huizhouyingjian": "惠州硬件研发",
  "jishuzhichi": "技术支持",
  "kehu": "客户",
  "guojikehu": "国际客户",
  "pingtaizhongxin": "平台中心",
  "shenzhenruanjian": "深圳软件研发",
  "shenzhenyingjian": "深圳硬件研发",
  "wu": "无",
  "kaiqi": "开启",
  "daipingjia": "待评价",
  "yizhuanchu": "已转出",
  "yiwancheng": "已完成",
  "daiwancheng": "待完成",
  //dialog message
  "fpfaecg": "分配FAE成功",
  "cggychfae": "此工单已撤回至FAE",
  "xgcg": "修改成功",
  "zhgc": "转化成功",
  "cgdych": "此工单已重启",
  "cgdywc": "此工单已完成",
  "cgdywc": "此工单已完成",
  "gxcg": "更新成功",
  "hscg": "回溯成功",
  "sccg": "删除成功",

  "faexq": "FAE详情",
  "qtjx":"其他机型",
  "zidingyi":"自定义",

  "zhuangtai":"状态",
  "tapdlianjie":"TAPD链接",
  "yfkd":"研发卡点",
  "jjfangan":"解决方案",

  "xuqiu":"需求",
  "yanfa":"研发",

  "chuangjianchenggong":"创建成功",

  "kfgdfwxt":"客服工单服务系统",
  "denglu":"登录",
  "kfgdxt":"客服工单系统",
  "gdgl":"工单管理",
  "faeAndyw":"FAE/业务",
  "yhtc":"用户退出",
  "mmxg":"密码修改",

  "sftc":"是否退出",
  "tuichu":"退出",
  "tixing":"提醒",

  "yuanmima":"原密码",
  "xinmima":"新密码", 

  "qrkswt":"确认是否客诉",

  "complaintFlag":"是否客诉",

  
}
